:root {
  /* sfumature scure primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* sfumature chiare del primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* grigi scuri - usati per headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* scala di grigi per paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 180ms linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  --max-width: 1170px;
  --fixed-width: 450px;
  --clr-orange-1: hsl(12, 83%, 98%);
  --clr-orange-2: hsl(14, 91%, 95%);
  --clr-orange-3: hsl(12, 89%, 89%);
  --clr-orange-4: hsl(13, 87%, 82%);
  --clr-orange-5: hsl(13, 88%, 68%);
  --clr-orange-6: hsl(13, 88%, 55%);
  --clr-orange-7: hsl(13, 74%, 49%);
  --clr-orange-8: hsl(13, 74%, 33%);
  --clr-orange-9: hsl(13, 73%, 25%);
  --clr-orange-10: hsl(13, 73%, 16%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(242, 255, 255);
}

/*
GLOBAL STYLES
*/

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  letter-spacing: var(--spacing);
}

.item {
  width: var(--fixed-width);
  background-color: var(--clr-white);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto;
  padding: 2rem;
}

.button {
  border-radius: 3px;
  padding: 0.2rem 0.3rem !important;
  border: transparent;
  background-color: var(--clr-primary-5);
  color: var(--clr-white);
  transition: var(--transition);
}

button:hover {
  background-color: var(--clr-primary-1);
}

.delete-button {
  background-color: transparent;
  color: var(--clr-red-dark);
  font-size: 1.1em;
}

.delete-button:hover {
  color: var(--clr-white);
  background-color: var(--clr-red-dark);
}

.reset-button {
  padding: 0rem 2rem;
  background-color: var(--clr-orange-6);
  color: var(--clr-white);
  font-size: 1.1em;
}

.reset-button:hover {
  background-color: var(--clr-orange-7);
  box-shadow: 0px 0px 7px var(--light-shadow);
}

.users {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem auto;
}
.users li {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--clr-white);
  padding: 1rem 2rem;
  border-radius: var(--radius);
  text-align: left;
}
.users img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}
.users h4 {
  margin-bottom: 0.15rem;
}
.users a {
  color: var(--clr-grey-5);
  text-transform: capitalize;
}

label {
  justify-self: start;
}

.modal-section {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-modal {
  opacity: 1;
  visibility: visible;
}


/* SPINNER */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: blue;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Wait */
.wait-img {
  display: block;
}

.wait-img img {
  width: 250px;
  border-radius: 50%;
  margin-right: 1rem;  
}

/* Griglia */
.ruzzle {
  display: grid;
  grid-template-columns: 90px 90px 90px 90px 90px;
  grid-template-rows: 90px 90px 90px 90px 90px;
  background-color: #2196F3;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  justify-content: center;
  align-content: center;
}

.ruzzle-4 {
  display: grid;
  grid-template-columns: 108px 108px 108px 108px;
  grid-template-rows: 108px 108px 108px 108px;
  background-color: #2196F3;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
  justify-content: center;
  align-content: center;
}

.ruzzle-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 0px;
  font-size: 50px;
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 1rem;
  vertical-align: middle;
}

.ruzzle-item-4 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 0px;
  font-size: 64px;
  text-align: center;
  width: 98px;
  height: 98px;
  border-radius: 1rem;
  vertical-align: middle;
}

.ruzzle-button {
  display: grid;
  grid-template-columns: 25px 400px 25px;
  grid-template-rows: 50px;
  background-color: transparent;
  padding: 0px;
  width: fit-content;
  border-radius: 1rem;
}

.ruzzle-button-item {
  padding: 0px;
  font-size: 30px;
  background-color: #002849;
  color: aliceblue;
  text-align: center;
  width: 400px;
  height: 50px;
  border-radius: 2rem;
  vertical-align: middle;
}

.ruzzle-progressbar {
  display: grid;
  grid-template-columns: 25px 400px 25px;
  grid-template-rows: 20px;
  background-color: transparent;
  padding: 25px;
  width: fit-content;
}

.ruzzle-finetempo-item {
  padding: 0px;
  font-size: 30px;
  background-color: #ff0000;
  color: rgb(252, 213, 85);
  text-align: center;
  width: 400px;
  height: 50px;
  border-radius: 5px;
  vertical-align: middle;
}

section {
  display: grid;
  justify-content: center;
  align-content: center;

  gap: 4px;
  grid-auto-flow: column;
}

.text-middle {
  vertical-align: middle;
  text-align: center;
}

.centratutto {
  background-color: transparent;
}